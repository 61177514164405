<template>
    <div class="mainx">
        <BaseHeader title="Recusas do cartão de crédito"></BaseHeader>
        <b-breadcrumb
            :items="[{ text: 'Dashboard', href: '/dashboard' }, { text: $t('views.seller.reports.text_1428'), href: '/relatorios' }, { text: 'Recusas do cartão de crédito' }]"
            class="bg-white m-0 p-0"></b-breadcrumb>
        <div class="divider"></div>
        <div class="container-x">
            <FilterExport />
            <div class="box-stateless" style="padding: 32px">
                <CreditCardResume subtitle="Total de recusas" :labels="chart_data ? Object.keys(chart_data) : []"
                    :data="chart_data ? Object.values(chart_data) : []">
                    <template v-slot:extra>
                        <div class="label">
                        <BigNumber title="Vendas recuperadas (venda inteligente)" :value="counter_smart_sales"
                            size="md">
                            <template v-slot:tooltip>
                                <InfoTooltip
                                    label="Vendas originalmente recusadas que foram convertidas pelo processo de venda inteligente" />
                            </template>
                        </BigNumber>
                    </div>
                    <div class="label">
                        <BigNumber title="Vendas inteligentes recusadas" :value="counter_smart_sales_refused" size="md">
                            <template v-slot:tooltip>
                                <InfoTooltip
                                    label="O processo de venda inteligente tentou recuperar a venda, porém também foi recusado" />
                            </template>
                        </BigNumber>

                    </div>
                    </template>
                </CreditCardResume>
            </div>
            <div class="box-stateless">
                <Table
                    :columns="['ID Venda', 'Nome da oferta', 'Data da compra', 'E-mail', 'Valor da oferta', 'Motivo da recusa', 'Venda inteligente']"
                    :rows="data"
                    :links="{
                        'ID Venda': (id) => `/vendas?s_id=${id}`,
                    }"
                >
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue';
import Container from "@/components/Seller/Reports/Container.vue";
import Table from "@/components/Seller/Reports/Table.vue";
import ReportService from "@/services/resources/ReportService";
import FilterExport from './FilterExport.vue'
import CreditCardResume from '@/components/Seller/Reports/CreditCardResume.vue'
import BigNumber from '@/components/Seller/Reports/BigNumber.vue'
import InfoTooltip from '@/components/Seller/Reports/InfoTooltip.vue'

const Report = ReportService.build();

export default {
    components: {
        BaseHeader,
        Container,
        Table,
        FilterExport,
        CreditCardResume,
        BigNumber,
        InfoTooltip
    },
    computed: {
        filter() {
            return this.$store.getters.getFilters;
        }
    },
    data() {
        return {
            data: [],
            chart_data: null,
            loading: false,
            counter_smart_sales: 0,
            counter_smart_sales_refused: 0,
        };
    },
    methods: {
        fetchReport() {
            this.loading = true;
            Report.search({ url: "sales-refused", ...this.filter, type: "full" })
                .then((response) => {
                    this.data = response['sales'];
                    this.chart_data = response['chart_data'];
                    this.counter_smart_sales = response['sales_smart_sale'];
                    this.counter_smart_sales_refused = response['sales_smart_sale_refused'];
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e);
                });
        }
    },
};
</script>

<style scoped>
.box-stateless {
    border: 1px solid #D7DAE2;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    max-width: 1660px;
}

.container-x {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 24px;
}
</style>
<style>
.box-stateless {
    table thead tr th:first-child {
        border-top-left-radius: 8px;
    }

    table thead tr th:last-child {
        border-top-right-radius: 8px;
    }
}
</style>